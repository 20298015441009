import React from 'react'
import { useFraudIndicators } from '../../FraudIndicatorsProvider'
import { Box, Stack } from '@mui/material'
import classes from '../../styles'
import SwitchWithLabel from '../SwitchWithLabel'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ButtonSecondary } from 'components-new'

const BottomBar = () => {
  const { isEnabled, setIsActivationModalOpen } = useFraudIndicators()
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        sx={classes.saveSection}
      >
        <Box sx={classes.parentContainer}>
          <SwitchWithLabel
            labelChecked={<FormattedMessage id={intl.fraudIndicators('active')} />}
            labelUnChecked={<FormattedMessage id={intl.fraudIndicators('inactive')} />}
            checked={isEnabled}
            onChange={() => setIsActivationModalOpen(true)}
          />
          <ButtonSecondary
            data-cy="fraud-indicators-save"
            onClick={() => {
              /* TODO: api integration on onClick */
            }}
          >
            <FormattedMessage id={intl.fraudIndicators('save-changes')} />
          </ButtonSecondary>
        </Box>
      </Stack>
    </>
  )
}

export default BottomBar
