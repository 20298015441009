import * as React from 'react'

import { creditAutomatorTableActions } from './actions'
import {
  CreditAutomatorContextState,
  CreditAutomatorContextDispatch,
} from './ECPTableContext.types'
import { creditAutomatorTableReducer } from './reducer'
import { apiParameters } from '../ECPMonitoringTable.model'

const CreditAutomatorTableContext = React.createContext<
  | {
      state: CreditAutomatorContextState
      dispatch: CreditAutomatorContextDispatch
      actions: ReturnType<typeof creditAutomatorTableActions>
    }
  | undefined
>(undefined)
const defaultState = {
  page: 1,
  perPageLimit: apiParameters.limit,
  sortBy: null,
  pageCount: undefined,
  listId: undefined,
}

function CreditAutomatorTableProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(creditAutomatorTableReducer, defaultState)

  // NOTE: you *might* need to memoize this value
  const value = React.useMemo(() => {
    const actions = creditAutomatorTableActions(dispatch)
    return { state, actions, dispatch }
  }, [state, dispatch])

  return (
    <CreditAutomatorTableContext.Provider value={value}>
      {children}
    </CreditAutomatorTableContext.Provider>
  )
}

function useCreditAutomatorTableContext() {
  const context = React.useContext(CreditAutomatorTableContext)
  if (context === undefined) {
    throw new Error(
      'CreditAutomatorTable must be used within a CreditAutomatorTableProvider'
    )
  }
  return context
}

export { CreditAutomatorTableProvider, useCreditAutomatorTableContext }
