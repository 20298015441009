import {
  SET_PAGE,
  SET_PAGE_LIMIT,
  SET_SORT_BY,
  SET_PAGE_COUNT,
  SET_LIST_ID,
} from './constants'
import { SortBy } from './ECPTableContext.types'
import { CreditAutomatorContextDispatch } from './ECPTableContext.types'

export const creditAutomatorTableActions = (
  dispatch: CreditAutomatorContextDispatch
) => ({
  updatePage: (page: number) => dispatch({ type: SET_PAGE, payload: { newPage: page } }),
  updatePerPageLimit: (limit: string | number) =>
    dispatch({ type: SET_PAGE_LIMIT, payload: { limit } }),
  updateSortBy: (sorting: SortBy) =>
    dispatch({ type: SET_SORT_BY, payload: { sorting } }),
  updatePageCount: (pageCount: number) =>
    dispatch({ type: SET_PAGE_COUNT, payload: { pageCount } }),
  updateListId: (listId: number) => dispatch({ type: SET_LIST_ID, payload: { listId } }),
})
