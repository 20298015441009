import { Modal } from '@mui/material'
import ModalCanvas from './ModalCanvas'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { useFraudIndicators } from 'pages/fraud-indicators/FraudIndicatorsProvider'

const ActivateFraudIndicatorsModal = () => {
  const {
    isEnabled,
    setIsEnabled,
    isActivationModalOpen: isOpen,
    setIsActivationModalOpen: setIsOpen,
  } = useFraudIndicators()
  const reactIntl = useIntl()

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} closeAfterTransition>
      {isEnabled ? (
        <ModalCanvas
          title={reactIntl.formatMessage({
            id: intl.fraudIndicators('deactivate-fraud-indicators-title'),
          })}
          text={reactIntl.formatMessage({
            id: intl.fraudIndicators('deactivate-fraud-indicators-description'),
          })}
          primaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('continue'),
          })}
          secondaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('cancel'),
          })}
          primaryBtnFunc={() => {
            // TODO: persist the data to the backend
            setIsEnabled(false)
            setIsOpen(false)
          }}
          secondaryBtnFunc={() => setIsOpen(false)}
          closeFn={() => setIsOpen(false)}
        />
      ) : (
        <ModalCanvas
          title={reactIntl.formatMessage({
            id: intl.fraudIndicators('activate-fraud-indicators-title'),
          })}
          text={reactIntl.formatMessage({
            id: intl.fraudIndicators('activate-fraud-indicators-description'),
          })}
          primaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('continue'),
          })}
          secondaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('cancel'),
          })}
          primaryBtnFunc={() => {
            // TODO: persist the data to the backend
            setIsEnabled(true)
            setIsOpen(false)
          }}
          secondaryBtnFunc={() => setIsOpen(false)}
          closeFn={() => setIsOpen(false)}
        />
      )}
    </Modal>
  )
}

export default ActivateFraudIndicatorsModal
