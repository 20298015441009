import { Stack } from '@mui/material'
import React from 'react'

type Props = {
  children: React.ReactNode
}

const FieldWrapper = ({ children }: Props) => {
  return (
    <Stack direction="column" spacing={1}>
      {children}
    </Stack>
  )
}

export default FieldWrapper
