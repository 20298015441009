import { Typography, Stack } from '@mui/material'
import styles from './Field.styles'

type Props = {
  title: string
  description: string
}

const FieldTitle = ({ title, description }: Props) => {
  return (
    <Stack direction="column" alignItems="flex-start" spacing={2}>
      <Typography variant="subtitle2" sx={styles.titleStyle}>
        {title}
      </Typography>
      <Typography sx={styles.subtitleStyle} variant="body2">
        {description}
      </Typography>
    </Stack>
  )
}

export default FieldTitle
