const titleStyle = {
  color: 'grey.800',
}

const subtitleStyle = {
  marginBottom: 1,
  color: 'grey.600',
}

export default {
  titleStyle,
  subtitleStyle,
}
