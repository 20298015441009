import { useIntl } from 'react-intl'
import { useFraudIndicators } from '../../FraudIndicatorsProvider'
import FraudIndicatorsActivator from '../FraudIndicatorsActivator'
import intl from 'localization/components'
import SectionContainer from './SectionContainer'

const FraudIndicatorsContent = () => {
  const { selectedCountry, indicators, setIndicators } = useFraudIndicators()
  const reactIntl = useIntl()

  const titles = {
    address: reactIntl.formatMessage({
      id: intl.fraudIndicators('address'),
    }),
    financial: reactIntl.formatMessage({
      id: intl.fraudIndicators('financial'),
    }),
  }

  const handleSwitchChange = (key: string, value: boolean) => {
    const [key1, key2] = key.split('-')
    setIndicators({
      ...indicators,
      [selectedCountry]: {
        ...indicators[selectedCountry],
        [key1]: {
          ...indicators[selectedCountry]?.[key1],
          [key2]: value,
        },
      },
    })
  }

  const generateActivator = (title: string, description: string, key: string) => {
    const [key1, key2] = key.split('-')
    return (
      <FraudIndicatorsActivator
        title={title}
        description={description}
        selected={indicators[selectedCountry][key1][key2]}
        setSelected={(value: boolean) => {
          handleSwitchChange(key, value)
        }}
        key={key}
      />
    )
  }

  const sections = {
    dk: [
      {
        title: titles.address,
        children: [
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-description'),
            }),
            'address-sameAddress'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-description'),
            }),
            'address-addressFrequency'
          ),
        ],
      },
      {
        title: titles.financial,
        children: [
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('autior-bankruptcies-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('autior-bankruptcies-description'),
            }),
            'financial-auditorBankruptcies'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('profit-employees-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('profit-employees-description'),
            }),
            'financial-profitEmployees'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('financial-statements-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('financial-statements-description'),
            }),
            'financial-financialStatements'
          ),
        ],
      },
    ],
    se: [
      {
        title: titles.address,
        children: [
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-description'),
            }),
            'address-sameAddress'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-description'),
            }),
            'address-addressFrequency'
          ),
        ],
      },
      {
        title: titles.financial,
        children: [
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('autior-bankruptcies-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('autior-bankruptcies-description'),
            }),
            'financial-auditorBankruptcies'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('profit-employees-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('profit-employees-description'),
            }),
            'financial-profitEmployees'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('financial-statements-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('financial-statements-description'),
            }),
            'financial-financialStatements'
          ),
        ],
      },
    ],
    fi: [
      {
        title: titles.address,
        children: [
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-description'),
            }),
            'address-sameAddress'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-description'),
            }),
            'address-addressFrequency'
          ),
        ],
      },
      {
        title: titles.financial,
        children: [
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('autior-bankruptcies-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('autior-bankruptcies-description'),
            }),
            'financial-auditorBankruptcies'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('profit-employees-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('profit-employees-description'),
            }),
            'financial-profitEmployees'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('financial-statements-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('financial-statements-description'),
            }),
            'financial-financialStatements'
          ),
        ],
      },
    ],
    no: [
      {
        title: titles.address,
        children: [
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-description'),
            }),
            'address-sameAddress'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-description'),
            }),
            'address-addressFrequency'
          ),
        ],
      },
      {
        title: titles.financial,
        children: [
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('autior-bankruptcies-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('autior-bankruptcies-description'),
            }),
            'financial-auditorBankruptcies'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('profit-employees-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('profit-employees-description'),
            }),
            'financial-profitEmployees'
          ),
          generateActivator(
            reactIntl.formatMessage({
              id: intl.fraudIndicators('financial-statements-title'),
            }),
            reactIntl.formatMessage({
              id: intl.fraudIndicators('financial-statements-description'),
            }),
            'financial-financialStatements'
          ),
        ],
      },
    ],
  }

  switch (selectedCountry) {
    case 'dk':
      return (
        <SectionContainer dataCy={'fraud-indicators-danish'} sections={sections.dk} />
      )
    case 'se':
      return (
        <SectionContainer dataCy={'fraud-indicators-swedish'} sections={sections.se} />
      )
    case 'no':
      return (
        <SectionContainer dataCy={'fraud-indicators-norwegian'} sections={sections.no} />
      )
    case 'fi':
      return (
        <SectionContainer dataCy={'fraud-indicators-finnish'} sections={sections.fi} />
      )
  }
}

export default FraudIndicatorsContent
