import { Divider, Stack } from '@mui/material'
import Section from '../Section'
import styles from '../../styles'
import { SectionContainerProps } from 'pages/fraud-indicators/types'

const SectionContainer: React.FC<SectionContainerProps> = ({ sections, dataCy }) => {
  return (
    <Stack
      direction="column"
      divider={<Divider orientation="horizontal" />}
      sx={styles.fraudIndicatorsContainer}
      data-cy={dataCy}
    >
      {sections.map(({ title, children }) => (
        <Section key={title} title={title}>
          {children}
        </Section>
      ))}
    </Stack>
  )
}

export default SectionContainer
