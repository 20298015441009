import { ChangeEvent } from 'react'
import { Stack, Switch, Typography } from '@mui/material'
import { styles } from './DualLabelSwitch.styles'
import intl from 'localization/components'
import { useIntl } from 'react-intl'

type Props = {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const DualLabelSwitch = ({ checked, onChange, ...rest }: Props) => {
  const reactIntl = useIntl()

  return (
    <Stack sx={styles.containerStyles} {...rest}>
      <Typography variant="body1" sx={styles.disabled}>
        {reactIntl.formatMessage({ id: intl.fraudIndicators('disabled') })}
      </Typography>
      <Switch
        data-cy="switch-with-label"
        checked={checked}
        onChange={onChange}
        sx={styles.switchStyles}
      />
      <Typography variant="body1">
        {reactIntl.formatMessage({ id: intl.fraudIndicators('enabled') })}
      </Typography>
    </Stack>
  )
}

export default DualLabelSwitch
