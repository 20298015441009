export const styles = {
  containerStyles: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 2,
  },
  disabled: {
    color: 'grey.600',
  },
  switchStyles: {
    '.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'success.700',
      opacity: 1,
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'common.white',
      opacity: 1,
      height: 20,
      width: 20,
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'grey.600',
      opacity: 1,
    },
  },
}
