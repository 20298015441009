import * as React from 'react'
import { Paper } from '@mui/material'
import { Table, TableLoading } from 'components-new'
import { TableWithToolbarProps } from './TableWithToolbar.types'
import { useCreditAutomatorTableContext } from 'pages/risk-monitoring/widgets/ECPMonitoringTable/context'
import ConditionallyRender from 'components/ui/ConditionallyRender'

const TableWithToolbar = ({
  tableData,
  formatData,
  columnsDefinition,
  getSelected,
  children,
  expandable = false,
  isLoading = false,
  enableReorder = false,
  listId,
  ...rest
}: TableWithToolbarProps) => {
  const { state, actions } = useCreditAutomatorTableContext()
  const { page, perPageLimit } = state
  return (
    <Paper sx={{ paddingTop: 2 }}>
      {children && children}

      <ConditionallyRender
        condition={isLoading}
        when={<TableLoading />}
        otherwise={
          columnsDefinition.length > 0 && (
            <Table
              tableData={tableData}
              formatData={formatData}
              columnsDefinition={columnsDefinition}
              page={page}
              perPageLimit={perPageLimit}
              setPerPageLimit={actions.updatePerPageLimit}
              setSortBy={actions.updateSortBy}
              setPage={actions.updatePage}
              getSelected={getSelected}
              expandable={expandable}
              enableReorder={enableReorder}
              listId={listId}
              {...rest}
            />
          )
        }
      />
    </Paper>
  )
}

export default TableWithToolbar
