export const styles = {
  containerStyles: (
    labelPosition: 'start' | 'end',
    lighterLabelColor: boolean,
    checked: boolean
  ) => ({
    flexDirection: labelPosition === 'start' ? 'row' : 'row-reverse',
    color: lighterLabelColor ? (checked ? 'grey.800' : 'grey.600') : 'grey.800',
    alignItems: 'center',
    gap: 3,
    justifyContent: labelPosition === 'start' ? 'flex-start' : 'flex-end',
  }),
  switchStyles: {
    '.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'success.700',
      opacity: 1,
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'common.white',
      opacity: 1,
      height: 20,
      width: 20,
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'grey.600',
      opacity: 1,
    },
  },
}
