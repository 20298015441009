import { HistoryHeader } from 'components'
import intl from 'localization/components'
import { useIntl } from 'react-intl'
import FraudIndicatorsTabs from './widgets/FraudIndicatorsTabs'
import FraudIndicatorsContent from './widgets/FraudIndicatorsContent'
import FraudIndicatorsProvider from './FraudIndicatorsProvider'
import SaveModal from './widgets/Modals/SaveModal'
import BottomBar from './widgets/BottomBar'
import ActivateFraudIndicatorsModal from './widgets/Modals/ActivateFraudIndicatorsModal'

const FraudIndicatorsPage = () => {
  const { messages } = useIntl()
  return (
    <FraudIndicatorsProvider>
      <HistoryHeader
        title={messages[intl.navigation('fraud-indicators')] as string}
        location="fraud-indicators"
        historyKey="fraud-indicators"
      />
      <FraudIndicatorsTabs />
      <FraudIndicatorsContent />
      <BottomBar />
      <SaveModal />
      <ActivateFraudIndicatorsModal />
    </FraudIndicatorsProvider>
  )
}

export default FraudIndicatorsPage
