import { createContext, useContext, useState } from 'react'
import { SupportedCountry } from 'types/general'
import { FraudIndicatorsType, Indicators } from './types'
import lodash from 'lodash'

const FraudIndicatorsContext = createContext<FraudIndicatorsType>({
  selectedCountry: 'dk',
  setSelectedCountry: () => {},
  indicators: { dk: {}, se: {}, no: {}, fi: {} },
  setIndicators: () => {},
  isEnabled: false,
  setIsEnabled: () => {},
  isSaveModalOpen: false,
  setIsSaveModalOpen: () => {},
  isActivationModalOpen: false,
  setIsActivationModalOpen: () => {},
  hasFraudIndicatorsChanged: false,
})

const initialData: Indicators = {
  dk: {
    address: {
      sameAddress: false,
      addressFrequency: false,
    },
    financial: {
      auditorBankruptcies: false,
      profitEmployees: false,
      financialStatements: false,
    },
  },
  se: {
    address: {
      sameAddress: false,
      addressFrequency: false,
    },
    financial: {
      auditorBankruptcies: false,
      profitEmployees: false,
      financialStatements: false,
    },
  },
  no: {
    address: {
      sameAddress: false,
      addressFrequency: false,
    },
    financial: {
      auditorBankruptcies: false,
      profitEmployees: false,
      financialStatements: false,
    },
  },
  fi: {
    address: {
      sameAddress: false,
      addressFrequency: false,
    },
    financial: {
      auditorBankruptcies: false,
      profitEmployees: false,
      financialStatements: false,
    },
  },
}

export const useFraudIndicators = () => useContext(FraudIndicatorsContext)

type Props = {
  children: React.ReactNode
}

const FraudIndicatorsProvider = ({ children }: Props) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<SupportedCountry>('dk')
  const [indicators, setIndicators] = useState(initialData)
  const [isEnabled, setIsEnabled] = useState(false)

  const checkHasFraudIndicatorsChanged = (): boolean => {
    // TODO get the initial payload from the API
    const initialPayload = initialData
    const currentPayload = indicators
    const res = !lodash.isEqual(initialPayload, currentPayload)
    return res
  }

  return (
    <FraudIndicatorsContext.Provider
      value={{
        selectedCountry,
        setSelectedCountry,
        indicators,
        setIndicators,
        isEnabled,
        setIsEnabled,
        isSaveModalOpen,
        setIsSaveModalOpen,
        isActivationModalOpen,
        setIsActivationModalOpen,
        hasFraudIndicatorsChanged: checkHasFraudIndicatorsChanged(),
      }}
    >
      {children}
    </FraudIndicatorsContext.Provider>
  )
}

export default FraudIndicatorsProvider
