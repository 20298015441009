import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { colors } from 'configs'
import {
  Stack,
  ConditionallyRender,
  ShowHideAllWrapper,
  Hide,
  CopyItem,
} from 'components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import NumberOfEmployees from './components/NumberOfEmployees'
import ProductionUnits from './components/ProductionUnits'
import Founders from './components/Founders'
import Auditors from './components/Auditors'
import Ean from './components/Ean'
import Tinglysning from './components/Tinglysning'
import EntryWithSubtitle from './components/EntryWithSubtitle'
import SecondaryNames from './components/SecondaryNames'
import Purpose from './components/Purpose'
import SimpleEntry from './components/SimpleEntry'
import { Entry, EntryTitle, EntryText, EntryContent } from './components/Entry'
import { navigation } from 'services/navigation'
import PhoneNumber from 'awesome-phonenumber'
import { isNil } from 'ramda'
import moment from 'moment'
import { handleAddressField, generateMapLocation } from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import intl from 'localization/components'
import AddInternalId from './components/AddInternalId'
import EditInternalId from './components/EditInternalId'
import {
  useCompanyBasics,
  usePropertiesForCompany,
  useDetailedNumberOfEmployees,
  useProductionUnits,
  useCompanyHistory,
  useCompanyRelations,
  useUserSettingsField,
  useUpdateUserSettingsField,
  useAccountStatus,
} from 'services/queries'
import { useCreditCheck } from 'pages/credit-check/context'
import { useIsCompanyActive } from 'services/hooks'
import { formatToUniversalDate } from 'utils/dateUtils'
import GeneralCompanyInformationSkeleton from './GeneralCompanyInformation.skeleton'
import { LoadingSkeleton } from 'components-new'
import { FAQ_LINK } from 'configs/navigation'
import { env } from 'services/env'
import { getFeatureFlag } from 'feature-flags/getFeatureFlag'
import { FEATURE_FLAGS } from 'feature-flags/FeatureFlags'

const useStyles = makeStyles((theme) => ({
  founders_auditors: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
}))

export const GeneralCompanyInformation = ({ isShowMore, isReArrange }) => {
  const reactIntl = useIntl()
  const { localId } = useCreditCheck()
  const companyBasicsQuery = useCompanyBasics(localId)
  const companyRelationsQuery = useCompanyRelations(localId)
  const propertiesForCompanyQuery = usePropertiesForCompany(localId)
  const detailedNumberOfEmployeesQuery = useDetailedNumberOfEmployees(localId)
  const productionUnitsQuery = useProductionUnits(localId)
  const accountStatus = useAccountStatus()

  const companyHistory = useCompanyHistory(localId)
  const historicalData = companyHistory.data
  let shownEntries = 5
  const detailedNumberOfEmployees = detailedNumberOfEmployeesQuery.data
  const productionUnits = productionUnitsQuery.data
  const {
    address,
    bank,
    advertisement_protection,
    company_secondary_names,
    company_name,
    company_name_changes_count,
    company_type,
    date_of_incorporation,
    email,
    webpage,
    local_organization_id,
    main_industry_code,
    number_of_employees,
    phone = {},
    powers_to_bind,
    registered_capital,
    address_changes_count,
    ean,
    vat,
    purpose,
    // relations = {},
    average_number_of_employees,
    listed,
    auditor_selected,
    financial_year,
    status,
    internal_id,
  } = companyBasicsQuery.data ?? {}
  const relations = companyRelationsQuery.data ?? {}
  const properties = propertiesForCompanyQuery.data ?? []
  const classes = useStyles()
  const history = useHistory()
  const { lang } = useSelector((state) => state.auth.user.local)
  const isCompanyActive = useIsCompanyActive()
  const [isShowNameHistory, setIsShowNameHistory] = React.useState(false)
  const [isShowCapitalHistory, setIsShowCapitalHistory] = React.useState(false)
  const [isShowAddressHistory, setIsShowAddressHistory] = React.useState(false)
  const [isAllEmailsShown, setIsAllEmailsShown] = React.useState(false)
  const { data: companyInfoFields } = useUserSettingsField('company_info')
  const { mutate: updateCompanyInfoFields } = useUpdateUserSettingsField({})
  const { phone_number } = phone
  const {
    rights: { global },
  } = accountStatus.data ?? {}
  const hasAccessToFraudModule = global.fraud_indicators

  const units = productionUnits?.map((unit) => {
    if (unit.valid_to) {
      return {
        ...unit,
        invalid: true,
      }
    } else {
      return {
        ...unit,
        invalid: false,
      }
    }
  })

  // Splitting units in to valid and invalid sorting each and stiching them back together :)
  let sortedProductionUnits = []
  if (units?.length) {
    sortedProductionUnits = [
      ...units
        ?.filter((x) => !x.invalid)
        ?.sort((a, b) =>
          a.valid_from === b.valid_from ? 0 : a.valid_from > b.valid_from ? -1 : 1
        ),
      ...units
        ?.filter((x) => x.invalid)
        ?.sort((a, b) =>
          a.valid_to === b.valid_to ? 0 : a.valid_to > b.valid_to ? -1 : 1
        ),
    ]
  }

  const formatted_phone =
    phone_number && !phone.hidden
      ? new PhoneNumber(phone_number, local_organization_id.country)
      : null
  const auditors = relations?.auditors ?? []
  const founders = relations?.founders ?? []
  moment.locale(lang ?? 'da_DK')
  const share_capital = registered_capital?.value
  const share_capital_currency = registered_capital?.currency || ''

  /**
   * Create contactable links (mailto, tel)
   */

  const contactable = (type) => (value) =>
    (
      <a href={`${type}:${value}`}>
        <Typography display="inline" variant="body2">
          {value}
        </Typography>
      </a>
    )

  const hasMultipleEmails = (value) => value?.startsWith('[')

  const mailable = contactable('mailto')
  const callable = contactable('tel')

  /**
   * Create a link to the address in Google Maps.
   */

  const linkToMap = (address) => {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://google.com/maps/place/${generateMapLocation(address)}`}
      >
        <Typography variant="body2" display="inline">
          {handleAddressField(address) ?? (
            <FormattedMessage id={intl.generic('missing-address')} />
          )}
        </Typography>
      </a>
    )
  }
  const reorder = (list, startIndex, endIndex) => {
    const newList = [...list]
    const [removed] = newList.splice(startIndex, 1)
    newList.splice(endIndex, 0, removed)
    return newList
  }

  const onDragEnd = (res) => {
    if (!res.destination) {
      return
    }
    const newOrder = reorder(companyInfoFields, res.source.index, res.destination.index)
    updateCompanyInfoFields({ field: 'company_info', data: newOrder })
  }

  const handleClick = (e, to) => {
    e.preventDefault()
    history.push(to)
  }

  const getListStyle = (isDraggingOver) => ({
    background: colors?.risikaLight,
    display: 'flex',
    flexDirection: 'column',
  })
  const getItemStyle = (isDragging, draggableStyle, order) => ({
    borderRadius: '0.5rem',
    marginBottom: '1rem',
    order: order,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // change background colour if dragging
    background: isDragging ? colors?.risikaContrast : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  })
  const getCorrectComponent = (key) => {
    switch (key) {
      case 'company-name':
        return (
          // Company Name
          <SimpleEntry
            isCopyable={company_name ?? ''}
            isHistory
            title={<FormattedMessage id={intl.companyInfo('company-name')} />}
            content={[
              <Typography display="inline" key={company_name} variant="body2">
                {company_name}
              </Typography>,
            ]}
            historyData={historicalData?.name?.filter((x) => x.data !== company_name)}
            setIsShowAllHistory={setIsShowNameHistory}
            isShowAllHistory={isShowNameHistory}
          />
        )
      // 'company-name-changes-count'
      case 'company-name-changes-count':
        return (
          hasAccessToFraudModule && (
            <SimpleEntry
              title={
                <FormattedMessage id={intl.companyInfo('company-name-changes-count')} />
              }
              content={
                env.FRAUD_MVP_DEMO_ENV === 'true' ||
                getFeatureFlag(FEATURE_FLAGS.FRAUD_INDICATORS)
                  ? [4]
                  : [company_name_changes_count]
              }
              toolTip={'company-name-changes-count-explanation'}
            />
          )
        )
      case 'company-id':
        return (
          // Local company ID
          local_organization_id ? (
            <SimpleEntry
              isCopyable={local_organization_id?.id ?? ''}
              title={<FormattedMessage id={intl.companyInfo('company-id')} />}
              content={[
                `${local_organization_id?.id} (${local_organization_id?.country})`,
              ]}
            />
          ) : null
        )
      case 'email':
        return (
          // Email
          email?.email && !email?.hidden ? (
            <SimpleEntry
              isCopyable={email?.email ?? ''}
              title={<FormattedMessage id={intl.companyInfo('email')} />}
              content={[
                email && !email.hidden && hasMultipleEmails(email.email)
                  ? JSON.parse(email.email).map((x, i, z) => {
                      return (
                        <ShowHideAllWrapper
                          setIsShowAll={setIsAllEmailsShown}
                          isShowAll={isAllEmailsShown}
                          key={i}
                          amountOfShowedItems={4}
                          iteration={i}
                          dataLength={z.length}
                        >
                          {mailable(x)}
                          <br />
                        </ShowHideAllWrapper>
                      )
                    })
                  : mailable(email?.email),
              ]}
            />
          ) : null
        )
      case 'phone':
        return (
          // Phone
          (email?.email && !email?.hidden) || formatted_phone ? (
            <SimpleEntry
              isLink
              isCopyable={
                formatted_phone ? formatted_phone?.getNumber('international') : ''
              }
              title={<FormattedMessage id={intl.companyInfo('phone')} />}
              content={[
                formatted_phone && callable(formatted_phone.getNumber('international')),
              ]}
            />
          ) : null
        )
      case 'status':
        return (
          // Website
          <SimpleEntry
            title={<FormattedMessage id={intl.mainRecommendation('status')} />}
            infoLink={FAQ_LINK}
            content={[
              <div key={'status'} style={{ display: 'flex' }}>
                <Typography variant="body2">{status}</Typography>
                <FontAwesomeIcon
                  style={{
                    width: '1rem',
                    height: '1rem',
                    backgroundColor: isCompanyActive
                      ? colors?.bg_rating_10
                      : colors?.bg_rating_1,
                    color: isCompanyActive ? colors?.bg_rating_10 : colors?.bg_rating_1,
                    borderRadius: '200rem',
                    marginLeft: '0.3rem',
                  }}
                  icon={faDotCircle}
                />
              </div>,
            ]}
          />
        )
      case 'webpage':
        return (
          // Website
          webpage ? (
            <SimpleEntry
              title={<FormattedMessage id={intl.companyInfo('webpage')} />}
              content={[
                <a
                  style={{ color: 'darkblue' }}
                  key="external link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://${webpage}`}
                >
                  <Typography variant="body2">{webpage}</Typography>
                </a>,
              ]}
            />
          ) : null
        )
      case 'address':
        return (
          // Address
          address?.city ? (
            <SimpleEntry
              isCopyable={address ? handleAddressField(address) : ''}
              isHistory
              historyData={historicalData?.address
                ?.map((x) => {
                  return {
                    ...x,
                    data: handleAddressField(x.data),
                  }
                })
                .slice(1)}
              title={<FormattedMessage id={intl.companyInfo('address')} />}
              content={[address ? linkToMap(address) : null]}
              setIsShowAllHistory={setIsShowAddressHistory}
              isShowAllHistory={isShowAddressHistory}
            />
          ) : null
        )
      // 'address-changes-count'
      case 'address-changes-count':
        return (
          hasAccessToFraudModule && (
            <SimpleEntry
              title={<FormattedMessage id={intl.companyInfo('address-changes-count')} />}
              content={
                env.FRAUD_MVP_DEMO_ENV === 'true' ||
                getFeatureFlag(FEATURE_FLAGS.FRAUD_INDICATORS)
                  ? [4]
                  : [address_changes_count]
              }
              toolTip={'address-changes-count-explanation'}
            />
          )
        )
      case 'ad-protection':
        return (
          // Ad protection
          <SimpleEntry
            title={<FormattedMessage id={intl.companyInfo('ad-protection')} />}
            content={[
              advertisement_protection ? (
                <FormattedMessage id={intl.companyInfo('ad-protection-denied')} />
              ) : (
                <FormattedMessage id={intl.companyInfo('ad-protection-allowed')} />
              ),
            ]}
          />
        )
      case 'registered-for-vat':
        return (
          // VAT
          !isNil(vat) ? (
            <SimpleEntry
              title={<FormattedMessage id={intl.companyInfo('registered-for-vat')} />}
              content={[
                vat ? (
                  <FormattedMessage id={intl.generic('yes')} />
                ) : (
                  <FormattedMessage id={intl.generic('no')} />
                ),
              ]}
            />
          ) : null
        )
      case 'ean':
        return (
          //  EAN
          ean?.length ? <Ean key="ean" data={ean} /> : null
        )
      case 'company-type':
        return (
          // Company type
          <SimpleEntry
            title={<FormattedMessage id={intl.companyInfo('company-type')} />}
            content={[company_type?.long]}
          />
        )
      case 'date-of-establishment':
        return (
          //  Date of establishment
          <SimpleEntry
            title={<FormattedMessage id={intl.companyInfo('date-of-establishment')} />}
            content={[
              date_of_incorporation === null ? (
                '-'
              ) : date_of_incorporation === '0001-01-01' ? (
                <FormattedMessage id={intl.companyInfo('date-of-incorporation')} />
              ) : (
                formatToUniversalDate(date_of_incorporation)
              ),
            ]}
          />
        )
      case 'founders_auditors':
        return (
          // Founders
          founders.length || isReArrange ? (
            <Founders key="founders_auditors" data={founders} handleClick={handleClick} />
          ) : null
        )
      case 'auditors':
        return (
          // Auditors
          auditors.filter((x) => x?.functions?.length).length || isReArrange ? (
            <Auditors
              key="auditors"
              data={auditors.filter((x) => x?.functions?.[0]?.valid_to === null)}
              handleClick={handleClick}
            />
          ) : null
        )
      case 'auditor_selected':
        return (
          // Auditor Selected
          auditor_selected !== null ? (
            <SimpleEntry
              title={
                <FormattedMessage id={intl.mainRecommendation('auditor_selected')} />
              }
              content={[
                auditor_selected ? (
                  <FormattedMessage id={intl.generic('yes')} />
                ) : (
                  <FormattedMessage id={intl.generic('no')} />
                ),
              ]}
            />
          ) : null
        )
      case 'financial_year':
        return (
          // Fianncial Year
          financial_year?.start ? (
            <SimpleEntry
              title={<FormattedMessage id={intl.companyInfo('financial_year')} />}
              content={[
                `${formatToUniversalDate(financial_year?.start, true)}
                      - ${formatToUniversalDate(financial_year?.end, true)}`,
              ]}
            />
          ) : null
        )
      case 'bank':
        return (
          // Bank
          bank?.company_name ? (
            <SimpleEntry
              title={<FormattedMessage id={intl.companyInfo('bank')} />}
              elementType="div"
              content={[
                <span
                  key={Math.random()}
                  className={classes.founders_auditors}
                  onClick={(e) =>
                    handleClick(e, navigation.creditCheck(bank?.local_organization_id))
                  }
                >
                  {bank?.company_name}
                </span>,
              ]}
            />
          ) : null
        )
      case 'share-capital':
        return (
          // Share capital
          <SimpleEntry
            dataCy="general-company-info-share-capital"
            isHistory
            title={<FormattedMessage id={intl.companyInfo('share-capital')} />}
            content={[
              <>
                {share_capital_currency} <FormattedNumber value={share_capital} />
              </>,
            ]}
            historyData={historicalData?.capital
              ?.map((x) => ({
                ...x,
                data: `${Object.keys(x.data)[0]} ${reactIntl.formatNumber(
                  x.data[Object.keys(x.data)[0]]
                )}`,
              }))
              .filter((x) => x.valid_to)}
            setIsShowAllHistory={setIsShowCapitalHistory}
            isShowAllHistory={isShowCapitalHistory}
          />
        )
      case 'powers-to-bind':
        return (
          // Powers to bind
          powers_to_bind ? (
            <SimpleEntry
              title={<FormattedMessage id={intl.companyInfo('powers-to-bind')} />}
              elementType="div"
              content={[powers_to_bind?.trim()]}
            />
          ) : null
        )
      case 'purpose':
        return (
          // Purpose
          purpose || isReArrange ? (
            <Purpose key="purpose" condition={purpose} purpose={purpose} />
          ) : null
        )
      case 'number-of-employees':
        return (
          // Number Of Employees
          detailedNumberOfEmployees?.length ||
            number_of_employees?.interval ||
            isReArrange ? (
            <NumberOfEmployees
              key="number-of-employees"
              detailedNumberOfEmployees={detailedNumberOfEmployees}
              number_of_employees={number_of_employees}
              average_number_of_employees={average_number_of_employees}
            />
          ) : null
        )
      case 'industry':
        return (
          // NACE && ISIC
          main_industry_code || isReArrange ? (
            <React.Fragment key="industry">
              <EntryWithSubtitle
                title={<FormattedMessage id={intl.companyInfo('industry')} />}
                data={main_industry_code.code}
                subtitle={main_industry_code.description}
              />
              <EntryWithSubtitle
                title={'NACE'}
                data={main_industry_code.nace.code}
                subtitle={main_industry_code.nace.description}
              />
              <EntryWithSubtitle
                title={'ISIC'}
                data={main_industry_code.isic.code}
                subtitle={main_industry_code.isic.description}
              />
            </React.Fragment>
          ) : null
        )
      case 'production-units':
        return (
          // Production units
          <ProductionUnits
            key="production-units"
            sortedProductionUnits={sortedProductionUnits}
            address={address}
          />
        )
      case 'listed':
        return (
          // Listed
          listed !== null ? (
            <SimpleEntry
              title={<FormattedMessage id={intl.companyInfo('listed')} />}
              //
              content={[
                listed === true ? (
                  <FormattedMessage id={intl.generic('yes')} />
                ) : (
                  <FormattedMessage id={intl.generic('no')} />
                ),
              ]}
            />
          ) : null
        )
      case 'secondary-names':
        return (
          // Secondary names
          company_secondary_names?.filter((x) => x.valid_to === null)?.length ? (
            <SecondaryNames
              key="secondary-names"
              company_secondary_names={company_secondary_names}
            />
          ) : null
        )
      case 'internal-id':
        return (
          <Entry key="internal-id">
            <EntryTitle>
              <FormattedMessage id={intl.companyInfo('internal-id')} />
            </EntryTitle>
            <EntryContent>
              <Hide when={internal_id == null}>
                <Stack spacing={2} items="center">
                  <EntryText>{internal_id}</EntryText>
                  <EditInternalId
                    internalId={internal_id}
                    localId={local_organization_id}
                  />
                  <CopyItem item={internal_id} />
                </Stack>
              </Hide>
              <Hide when={internal_id != null}>
                <AddInternalId internalId={internal_id} localId={local_organization_id} />
              </Hide>
            </EntryContent>
          </Entry>
        )
      case 'property':
        return (
          // Tinglysning  // Property
          <Tinglysning properties={properties} />
        )

      default:
        return ''
    }
  }

  const isLoading =
    propertiesForCompanyQuery.isLoading ||
    detailedNumberOfEmployeesQuery.isLoading ||
    productionUnitsQuery.isLoading ||
    companyHistory.isLoading

  return (
    <LoadingSkeleton
      SkeletonComponent={GeneralCompanyInformationSkeleton}
      isLoading={isLoading}
    >
      <Stack
        data-cy="generalCompanyInformation"
        style={{ width: '100%', padding: '20px 20px 0 20px', marginBottom: '4px' }}
        direction="column"
        spacing={6}
      >
        <Stack direction="column" spacing={1}>
          <ConditionallyRender
            condition={isReArrange}
            when={
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {companyInfoFields?.map(({ key }, i) => (
                        <Draggable key={key} draggableId={key} index={i}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {getCorrectComponent(key)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            }
            otherwise={companyInfoFields?.map(({ key }, i) => {
              const component = getCorrectComponent(key)
              if (!component) {
                shownEntries = shownEntries + 1
              }
              return (
                <ConditionallyRender
                  condition={isShowMore || i <= shownEntries}
                  key={key}
                  when={component}
                />
              )
            })}
          />
        </Stack>
      </Stack>
    </LoadingSkeleton>
  )
}

export default GeneralCompanyInformation
