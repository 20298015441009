import { Tab, Tabs } from '@mui/material'
import { IntlTypography } from 'components-new'
import { countryMap } from 'configs/constants/system'
import { useAccountStatus } from 'services/queries'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { useFraudIndicators } from '../../FraudIndicatorsProvider'
import { SupportedCountry, FraudIndicatorTabs } from 'types/general'

const tabStyles = {
  ' .MuiTabs-scroller': { overflow: 'scroll !important' },
}

const FraudIndicatorsTabs = () => {
  const {
    selectedCountry,
    setSelectedCountry,
    hasFraudIndicatorsChanged,
    setIsSaveModalOpen,
  } = useFraudIndicators()
  const { data: accountStatus } = useAccountStatus()

  const availableCountries = useMemo(() => {
    return accountStatus?.legacy_rights?.allow_countries || ['DK']
  }, [accountStatus?.legacy_rights?.allow_countries])

  const checkIfUserHasCountry = (country: string) => {
    if (accountStatus) {
      return availableCountries.includes(country.toUpperCase())
    }
    return false
  }

  const handleTabChange = useCallback(
    (_: ChangeEvent<{}>, newVal: SupportedCountry) => {
      if (hasFraudIndicatorsChanged) {
        setIsSaveModalOpen(true)
      } else {
        setSelectedCountry(newVal)
      }
    },
    [setSelectedCountry, setIsSaveModalOpen, hasFraudIndicatorsChanged]
  )

  return (
    <Tabs
      sx={tabStyles}
      data-cy="fraud-indicators-country-selector"
      value={selectedCountry}
      onChange={handleTabChange}
    >
      {FraudIndicatorTabs.map((countryTab) =>
        checkIfUserHasCountry(countryTab.toLowerCase()) ? (
          <Tab
            data-cy={`tab-${countryTab.toLowerCase()}`}
            label={
              <IntlTypography variant="subtitle2" labelKey={countryMap[countryTab]} />
            }
            value={countryTab.toLowerCase()}
            key={countryTab}
          />
        ) : null
      )}
    </Tabs>
  )
}

export default FraudIndicatorsTabs
