import { AxiosPromise } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import { accountStatus } from 'services/api'
import { AccountStatusResponse } from 'services/api/endpoints/risikaAPI/accountStatus'
import { ReduxRootState } from 'store_deprecated/types'

export const ACCOUNT_STATUS = 'accountStatus'

export function useAccountStatus(
  options?: Omit<UseQueryOptions<AccountStatusResponse, Error>, 'queryKey' | 'queryFn'>
) {
  const { authenticated } = useSelector((state: ReduxRootState) => state?.auth?.login)

  return useQuery<AccountStatusResponse, Error>(ACCOUNT_STATUS, () => accountStatus(), {
    staleTime: 100000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: authenticated,
    ...options,
  })
}

export async function getAxiosData<T>(promise: AxiosPromise<T>) {
  return promise.then((res) => res.data)
}
